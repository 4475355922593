import * as React from 'react';
import {cloneElement} from 'react';
import {
  CreateButton,
  EditButton,
  ExportButton,
  List,
  ListButton,
  Datagrid,
  TextField,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  ReferenceField,
} from 'react-admin';

const ActiveField = ({record, source}) => {
  record = {
    [source]: record.active == 0 ? 'No' : 'Yes',
  };
  return <TextField record={record} source={source} />;
};

const ListActions = (props) => {
  const {className, exporter, filters, maxResults, ...rest} = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

export const CourtList = (props) => {
  
  const locationId = localStorage.getItem('locationId');

  return (
    <List
      {...props}
      actions={<ListActions />}
      filter={{locationId: locationId}}
      bulkActionButtons={false}>
      <Datagrid>
        <TextField source='name' />
        <TextField source='price' />
        <ActiveField source='active' />
        <TextField source='penaltyPercentage' />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CourtList;
