exports.UserRoles = {
  USER: 0,
  STAFF: 1,
  ADMIN: 2,
};

exports.ReservationTypes = {
  NORMAL: 0,
  CANCELLED: 1,
  NOSHOW: 2,
  PAID: 3,
};

exports.EventsBackgroundColor = {
  UNAVAILABILITY: "grey",
  PAID: "#4caf50",
  NORMAL: "#f47320",
};

exports.EventsBorderColor = {
  UNAVAILABILITY: "black",
  PAID: "black",
  NORMAL: "black",
};
