import PriceIcon from '@material-ui/icons/Lock';

// import PriceList from './PriceList';
import PriceEdit from './PriceEdit';
import PriceCreate from './PriceCreate';

export default {
  // list: PriceList,
  edit: PriceEdit,
  create: PriceCreate,
  icon: PriceIcon,
};
