// export const getDate = (d) => {
//   var date = new Date(d);
//   var newDate = new Date(
//     2 * 60 * 60000 + date.valueOf() + date.getTimezoneOffset() * 60000,
//   );
//   // var ampm = newDate.getHours() < 12 ? ' AM' : ' PM';
//   var strDate = newDate + '';
//   return strDate.substring(0, strDate.indexOf(' GMT')); // + ampm;
// };

export const getDate = (d, includeTime = true, options) => {
  var date = new Date(d);

  const dateOptions = options
    ? options
    : {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        weekday: "short",
      };
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    date.toLocaleDateString("en-GB", dateOptions) +
    (includeTime ? ", " + date.toLocaleTimeString("en-US", timeOptions) : "")
  );
};

