import {API_URL} from '../constants';
import api from '../services/api';

const getLoginStatus = () => localStorage.getItem('isLoggedIn') === 'true';
const setLoginStatus = (status) => localStorage.setItem('isLoggedIn', status);

const login = async ({username, password}) => {
  const request = new Request(`${API_URL}/authenticate`, {
    method: 'POST',
    body: JSON.stringify({username, password}),
    headers: new Headers({'Content-Type': 'application/json'}),
    credentials: 'include',
  });

  const response = await fetch(request);
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject();
  } else {
    setLoginStatus(true);
    const data = await response.json();
    localStorage.setItem('token', data.token);
    localStorage.setItem('locationId', data.locationId);
    api.setAuthToken(data.token);
  }
  return Promise.resolve();
};

const logout = async () => {
  const token = localStorage.getItem('token');
  const isLoggedIn = getLoginStatus();
  if (isLoggedIn) {
    const request = new Request(`${API_URL}/logout`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      credentials: 'include',
    });

    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      return Promise.reject();
    }
  }
  setLoginStatus(false);
  return Promise.resolve();
};


const checkError = (err) => {
  const status = err.status;
  if (status === 401 || status === 403) {
    setLoginStatus(false);
    return Promise.reject();
  }
  return Promise.resolve();
};

const checkAuth = () => {
  const isLoggedIn = getLoginStatus();
  const token = localStorage.getItem('token');
  api.setAuthToken(token);
  return isLoggedIn ? Promise.resolve() : Promise.reject();
};

export default {
  login,
  logout,
  checkError,
  checkAuth,
  getPermissions: (params) => Promise.resolve(),
};
