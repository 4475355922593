import UnavailabilityIcon from '@material-ui/icons/Lock';

// import UnavailabilityList from './UnavailabilityList';
import UnavailabilityEdit from './UnavailabilityEdit';
import UnavailabilityCreate from './UnavailabilityCreate';

export default {
  // list: UnavailabilityList,
  edit: UnavailabilityEdit,
  create: UnavailabilityCreate,
  icon: UnavailabilityIcon,
};
