import React from 'react';
import {Admin, Resource} from 'react-admin';
import './App.css';
import authProvider from './auth/authProvider';
import courts from './courts';
import unavailability from './unavailability';
import prices from './prices';
import noshow from './noshow';
import paid from './paid';
import myDataProvider from './myDataProvider';
import MyLayout from './components/Layout';
import {theme} from './assets/theme/theme';

function App() {
  return (
    <Admin
    authProvider={authProvider}
    layout={MyLayout}
    theme={theme}
    dataProvider={myDataProvider}>
    <Resource name='courts' {...courts} />
    {/* <Resource name='staff' {...staff} /> */}
    <Resource name='unavailability' {...unavailability} />
    <Resource name='prices' {...prices} />
    <Resource name='noshow' {...noshow} />
    <Resource name='paid' {...paid} />
  </Admin>
  );
}

export default App;
