import * as React from 'react';
import {
  Edit,
  required,
  SimpleForm,
  TextField,
  ReferenceField,
  CheckboxGroupInput,
  NumberInput,
  SaveButton,
  DeleteButton,
  Toolbar,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import TimeInput from '../components/TimeInput';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const useStyles = makeStyles(styles);

const redirect = (basePath, id, data) => `/courts/${data.courtId}/prices`;

const CustomToolbar = (props) => {
  return (
    <Toolbar
      {...props}
      classes={useStyles()}
      style={{justifyContent: 'space-between'}}>
      <SaveButton />
      <DeleteButton
        undoable={false}
        redirect={`/courts/${props.record.courtId}/prices`}
      />
    </Toolbar>
  );
};

const PriceTitle = ({record}) => {
  return record ? <span>Court Prices</span> : null;
};

const PriceEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit title={<PriceTitle />} {...props}>
      <SimpleForm redirect={redirect} toolbar={<CustomToolbar />}>
        <ReferenceField
          source='courtId'
          reference='courts'
          formClassName={classes.inline}>
          <TextField source='name' formClassName={classes.inline} />
        </ReferenceField>
        <NumberInput
          source='price'
          formClassName={classes.inline}
          validate={required()}
        />
        <CheckboxGroupInput
          formClassName={classes.margin}
          source='weekDays'
          choices={[
            {id: '0', name: 'Sunday'},
            {id: '1', name: 'Monday'},
            {id: '2', name: 'Tuesday'},
            {id: '3', name: 'Wednesday'},
            {id: '4', name: 'Thursday'},
            {id: '5', name: 'Friday'},
            {id: '6', name: 'Saturday'},
          ]}
        />
        <TimeInput
          source='from'
          name='from'
          formClassName={classes.inline}
          validate={required()}
        />
        <TimeInput
          source='to'
          name='to'
          formClassName={classes.inline}
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};
export default PriceEdit;
