import * as React from 'react';
import {
  Edit,
  required,
  SimpleForm,
  TextField,
  ReferenceField,
  SaveButton,
  Toolbar,
  SelectInput,
  maxLength,
  useEditController,
} from 'react-admin';
import TimeInput from '../components/TimeInput';
import {makeStyles} from '@material-ui/core/styles';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const validateDescription = [maxLength(10000), required()];

const useStyles = makeStyles(styles);

const redirect = (basePath, id, data) => `/courts/${data.courtId}/noshow`;

const CustomToolbar = (props) => {
  return (
    <Toolbar
      {...props}
      classes={useStyles()}
      style={{justifyContent: 'space-between'}}>
      <SaveButton />
    </Toolbar>
  );
};

const NoshowTitle = ({record}) => {
  return record ? <span>No Show/Cancelled</span> : null;
};

const NoshowEdit = (props) => {
  const controllerProps = useEditController(props);
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = controllerProps;
  const classes = useStyles();
  return (
    <Edit title={<NoshowTitle />} {...props}>
      <SimpleForm redirect={redirect} toolbar={<CustomToolbar />}>
        <ReferenceField
          source='courtId'
          reference='courts'
          formClassName={classes.inline}>
          <TextField source='name' formClassName={classes.inline} />
        </ReferenceField>
        <SelectInput
          source='type'
          choices={
            record?.userType === 0
              ? [
                  {id: '0', name: 'Normal'},
                  {id: '1', name: 'Cancelled'},
                  {id: '2', name: 'No Show'},
                  {id: '3', name: 'Paid'},
                ]
              : [
                  {id: '0', name: 'Normal'},
                  {id: '1', name: 'Cancelled'},
                  {id: '3', name: 'Paid'},
                ]
          }
        />
      </SimpleForm>
    </Edit>
  );
};
export default NoshowEdit;
