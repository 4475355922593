import {fetchUtils} from 'react-admin';
import restProvider from 'ra-data-simple-rest';
import {API_URL} from './constants';

const httpClient = (url, options = {}) => {
  options.credentials = 'include';
  // options.headers.set();
  if (options.method == 'DELETE') delete options.headers;
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = restProvider(`${API_URL}`, httpClient);

const createOrUpdateWithImage = (type) => (resource, params) => {
  params.data.pictures = [];

  Object.keys(params.data).forEach((key) => {
    if (
      typeof params.data[key] === 'object' &&
      params.data[key]?.hasOwnProperty('rawFile')
    ) {
      params.data.pictures.push({key, ...params.data[key]});
    }
  });

  // if (resource !== 'posts' || !params.data.pictures) {
  if (params.data.pictures.length === 0) {
    // fallback to the default implementation
    return type === 'create'
      ? dataProvider.create(resource, params)
      : dataProvider.update(resource, params);
  }
  /**
   * For posts update only, convert uploaded image in base 64 and attach it to
   * the `picture` sent property, with `src` and `title` attributes.
   */

  // Freshly dropped pictures are File objects and must be converted to base64 strings

  const newPictures = params.data.pictures.filter(
    (p) => p.rawFile instanceof File,
  );
  const formerPictures = params.data.pictures.filter(
    (p) => !(p.rawFile instanceof File),
  );
  // const newPictures = pictures.filter((p) => p.rawFile instanceof File);
  // const formerPictures = pictures.filter((p) => !(p.rawFile instanceof File));

  return Promise.all(newPictures.map(convertFileToBase64))
    .then((base64Pictures) => {
      const pictures = {};
      base64Pictures.forEach((base64Picture) => {
        const {key, fileName, src} = base64Picture;
        pictures[key] = {fileName, src};
      });
      delete params.data.pictures;

      // return base64Pictures.map((picture64) => ({
      //   src: picture64,
      //   title: `${params.data.title}`,
      // }));
      return pictures;
      // return base64Pictures;
    })
    .then((transformedNewPictures) =>
      type === 'create'
        ? dataProvider.create(resource, {
            ...params,
            data: {
              ...params.data,
              ...transformedNewPictures,
              // pictures: [...transformedNewPictures, ...formerPictures],
            },
          })
        : dataProvider.update(resource, {
            ...params,
            data: {
              ...params.data,
              ...transformedNewPictures,
              // pictures: [...transformedNewPictures, ...formerPictures],
            },
          }),
    );
};

const myDataProvider = {
  ...dataProvider,
  create: createOrUpdateWithImage('create'),
  update: createOrUpdateWithImage('update'),
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () =>
      resolve({
        key: file.key,
        fileName: file.rawFile.name,
        src: reader.result,
      });
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

export default myDataProvider;
