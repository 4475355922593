import TextField from '@material-ui/core/TextField';
import {useInput} from 'react-admin';

const TimeInput = (props) => {
  const {
    input,
    meta: {touched, error},
  } = useInput(props);
  if (input.value === '24:00') {
    input.value = '00:00';
  }
  const inputProps = {
    step: 60*30,
  };
  return (
    <TextField
      type='time'
      variant='filled'
      inputProps={inputProps}
      label={input.name.toLowerCase().includes('from') ? 'From' : 'To'}
      InputLabelProps={{shrink: true}}
      {...input}
      // value={time}
    />
  );
};
export default TimeInput;
