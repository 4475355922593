import CourtIcon from '@material-ui/icons/SportsTennis';

import CourtList from './CourtList';
import CourtEdit from './CourtEdit';

export default {
  list: CourtList,
  edit: CourtEdit,
  icon: CourtIcon,
};
