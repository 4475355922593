import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/`,
  withCredentials: true,
});

const setAuthToken = (token) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearAuthToken = () => {
  axiosInstance.defaults.headers.common.Authorization = '';
};

const getEvents = (id, startDate, endDate) =>
  axiosInstance.get(`${id}/events?startDate=${startDate}&endDate=${endDate}`);

const changeReservationStatus = (id, type) =>
  axiosInstance.patch(`/${id}/type`, {type});

const updateReservation = (id, date, duration, penalty) =>
  axiosInstance.patch(`/reservations/${id}`, {date, duration, penalty});

  const reserveCourt = (id, date, duration, reserveeName, reserveePhone, reserveeID) =>
  axiosInstance.post(`/courts/${id}/reserve`, {date, duration, reserveeName, reserveePhone, reserveeID});

const getWorkingHours = (locationId) =>
  axiosInstance.get(`/${locationId}/workinghours`);

export default {
  setAuthToken,
  clearAuthToken,
  getEvents,
  updateReservation,
  changeReservationStatus,
  reserveCourt,
  getWorkingHours,
};
